<template>
	<div>
		<div class="bg bg3">
			<div class="mask"></div>
			<div class="text">
				<h1>产品系列</h1>
				<p>PRODICT SERIES</p>
			</div>
		</div>
		<div class="product-main">

			<div class="product-box">
				<h1>智能监测终端</h1>
				<ul>
					<div>
						<img src="../assets/img/vp-2109G.png" width="500" style="vertical-align: middle;" alt="电力智能精灵">
					</div>
					<li>
						<h3>实时监测</h3>
						<p>对违规室内充电实时监测</p>
					</li>
					<li>
						<h3>即使定位</h3>
						<p>发现即定位</p>
					</li>
					<li>
						<h3>及时发现</h3>
						<p>分钟级响应</p>
					</li>
					<li>
						<h3>即时处理</h3>
						<p>发现即可处理，支持远程断电</p>
					</li>


				</ul>
			</div>

			<div class="flex flex-hc flex-vc">
				<div>
					<img src="../assets/img/paicha.jpg" width="400" alt="电动单车智能充电产品">
					<h3 style="position: relative; top: -70px;">电动单车智能充电产品</h3>
				</div>
				<div style="margin-left: 150px;">
					<img src="../assets/img/cdz.png" width="400" alt="新能源汽车智能充电产品">
					<h3>新能源汽车智能充电产品</h3>
				</div>


			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: '微品能源-产品系列',
			meta: [{
					name: 'keywords',
					content: '微品能源,智慧用电,产品系列'
				},
				{
					name: 'description',
					content: '微品能源官方网站'
				}
			]
		}
	}
</script>

<style scoped>
	.bg {
		background-size: cover;
		width: 100%;
		height: 500px;
		position: relative;

	}

	.bg .mask {
		background: rgba(0, 0, 0, 0.4);
		position: absolute;
		width: 500px;
		height: 400px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.bg .text {
		font-size: 40px;
		color: #ffffff;
		font-weight: bold;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.bg3 {
		background-image: url('../assets/img/product.jpg');
	}

	.product-main {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 50px;
	}

	.product-box h1 {
		margin: 80px auto 0 auto;
	}

	.product-box ul {
		display: inline-block;
		position: relative;
		margin: 0;
	}

	.product-box ul li {


		position: absolute;
		text-align: left;
		list-style-type: disc;
	}

	.product-box ul li:nth-of-type(1) {
		top: 100px;
		left: -50px;
	}

	.product-box ul li:nth-of-type(1):before {
		content: '';
		border: 1px solid #DCDFE6;
		border-bottom: 1px solid transparent;
		border-left: 1px solid transparent;
		position: absolute;

		top: 20px;
		right: -50px;
		width: 30px;
		height: 40px;
		transform: skew(30deg);
	}


	.product-box ul li:nth-of-type(2) {
		top: 100px;
		right: -100px;
	}

	.product-box ul li:nth-of-type(2):before {
		content: '';
		border: 1px solid #DCDFE6;
		border-bottom: 1px solid transparent;
		border-right: 1px solid transparent;
		position: absolute;
		top: 20px;
		left: -80px;
		width: 30px;
		height: 80px;
		transform: skew(-30deg);
	}


	.product-box ul li:nth-of-type(3) {
		top: 400px;
		left: -50px;
	}

	.product-box ul li:nth-of-type(3):before {
		content: '';
		border: 1px solid #DCDFE6;
		border-top: 1px solid transparent;
		border-left: 1px solid transparent;
		position: absolute;
		top: -60px;
		right: -80px;
		width: 50px;
		height: 80px;
		transform: skew(-30deg);
	}

	.product-box ul li:nth-of-type(4) {
		top: 400px;
		right: -200px;
	}

	.product-box ul li:nth-of-type(4):before {
		content: '';
		border: 1px solid #DCDFE6;
		border-top: 1px solid transparent;
		border-right: 1px solid transparent;
		position: absolute;
		top: -60px;
		left: -80px;
		width: 20px;
		height: 80px;
		transform: skew(30deg);
	}



	.product-box ul li h3 {
		margin: 0;
		line-height: 30px;
	}

	.product-box ul li p {

		line-height: 30px;
		color: #727271;
		margin: 0;

	}
</style>
